<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-8 is-offset-2">
          <p class="title is-5 has-text-right">{{ sessionTitle }}</p>
        </div>
        <div class="column is-8 is-offset-2">
          <div
            class="columns"
            v-for="(item, index) in Object.entries(reportData)"
            :key="item.id"
          >
            <div class="column is-4 primary py-0 py-1">
              <strong>{{ normalizeText(item[0]) }}</strong>
            </div>
            <div class="column is-8 text-bg-color py-0 py-1">
              {{
                skipRecords.includes(index)
                  ? item[1]
                  : convertToCurrency(item[1], '₦')
              }}
            </div>
          </div>
        </div>
        <div class="column is-8 is-offset-2">
          <p class="title is-5">Class Groups without Fee Template</p>
          <p class="subtitle is-6">
            Students in a class group without Fee Template are excluded from
            this report. It is mandatory to define a fee template in order to
            get an accurate analysis.
          </p>
          <ol>
            <li v-for="item in additionalInfo" :key="item.name">
              <p>
                <strong class="has-text-danger">{{ item.name }}</strong>
              </p>
            </li>
          </ol>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { toCurrency } from '@/assets/js/currency_formatter'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  data() {
    return {
      pageTitle: 'School Fees Report',
      schoolFeesReport: {},
      reportData: {},
      additionalInfo: [],
      schoolId: null,
      termId: null,
      sessionTitle: null,
      skipRecords: [5, 6, 7, 8],
    }
  },
  methods: {
    normalizeText(value) {
      return value.replace(/\_/g, ' ')
    },
    convertToCurrency(value, symbol) {
      return toCurrency(value, symbol)
    },
  },
  watch: {
    schoolFeesReport(data) {
      this.reportData = data.report
      this.additionalInfo = data.additional_info
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.sessionTitle = term.session_title

      this.$apollo.addSmartQuery('schoolFeesReport', {
        query: gql`
          query SchoolFeesReportQuery($termId: ID!) {
            schoolFeesReport(termId: $termId)
          }
        `,
        variables() {
          return { termId: parseInt(this.termId) }
        },
        error(error) {
          this.error = JSON.stringify(error.message)
        },
      })

      this.$apollo.queries.schoolFeesReport.refetch()
    })

    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.schoolId}/fee_management`,
      },
      {
        name: 'Reports',
        route: `/school/${this.schoolId}/school_fees_reports`,
      },
    ])
  },
}
</script>
